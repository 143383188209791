<template>
  <Expand type="mobile-account-nav" transition="slide-left">
    <template v-slot:content>
      <div class="header__mobile-nav__view">
        <h2>Account</h2>
        <AccountLinks v-show="isLoggedIn" />
        <ul v-show="!isLoggedIn" class="large">
          <li><button @click="openHeaderMenuExpand('login')">Log In</button></li>
          <li><button @click="openHeaderMenuExpand('register')">Sign Up For Rewards</button></li>
        </ul>
      </div>
    </template>
  </Expand>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  import Expand from '@/components/header/Expand';
  import AccountLinks from '@/components/global/AccountLinks';

  export default {
    name: 'MobileAccountNav',

    components: {
      Expand,
      AccountLinks
    },

    computed: {
      ...mapGetters([
        'isLoggedIn'
      ])
    },

    methods: {
      ...mapActions([
        'openHeaderMenuExpand'
      ])
    }
  }
</script>
